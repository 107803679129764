.commentItem {
  width: 100%;
}

.inner {
  width: 100%;
  border-color: #d5d5d5;
  display: flex;
  align-items: stretch;
}

.inner .avatar {
  flex-shrink: 0;
  text-align: center;
}

.inner .avatar .icon_user {
  font-size: 0.6rem;
}

.inner .avatar :deep(.ant-avatar-string) {
  transform: translateX(-50%) !important;
}

.inner .avatar :deep(.ant-avatar) {
  margin-bottom: 0.1rem;
}

.inner .avatar .name {
  font-size: 0.18rem;
  color: #000000;
  line-height: 1;
  font-weight: bold;
}

.inner .txt {
  flex: 1;
  padding-left: 0.5rem;
}

.inner .txt .usertitle {
  font-size: 0;
  display: flex;
  align-items: flex-start;
}

.inner .txt .usertitle span {
  font-size: 0.16rem;
  padding: 0 0.2rem;
  line-height: 0.3rem;
  color: #fff;
  font-weight: bold;
  background-color: #f49133;
  border-radius: 0.15rem;
  margin-bottom: 0.1rem;
  margin-right: 0.1rem;
}

.inner .txt .content {
  font-size: 0.16rem;
  color: #555555;
  line-height: 1.75;
  margin-bottom: 0.2rem;
}

.inner .txt .handle {
  display: flex;
  margin-bottom: 0.2rem;
}

.inner .txt .handle .item {
  display: flex;
  align-items: center;
  margin-right: 0.35rem;
  cursor: pointer;
  font-size: 0.18rem;
}

.inner .txt .handle .item.active .svg-icon {
  color: #f49133;
}

.inner .txt .handle .item.active span {
  color: #f49133;
}

.inner .txt .handle .svg-icon {
  color: #bbb;
  margin-right: 0.1rem;
  transition: .3s;
}

.inner .txt .handle span {
  color: #555555;
}

.inner .txt .connment_list {
  padding: 0.3rem;
  background-color: #eee;
}

.inner .txt .connment_list .item {
  font-size: 0.16rem;
  color: #555555;
  line-height: 1.75;
}

.inner .txt .connment_list .sum {
  color: #f49133;
  cursor: pointer;
}

.input {
  overflow: hidden;
  transition: .3s;
}

.input :deep(.ant-input) {
  margin-top: 0.1rem;
}

.submit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.1rem;
}

.submit span {
  font-size: 0.14rem;
  color: #bbbbbb;
}

.submit button {
  text-align: center;
  min-width: 1rem;
  line-height: 0.35rem;
  font-size: 0.16rem;
  color: #fff;
  border-radius: 0.03rem;
  background-color: #f49133;
  cursor: pointer;
  transition: .3s;
}

.submit button:hover {
  box-shadow: 0 0 0.1rem 0 #f49133;
}