.theme {
  padding-bottom: 0.2rem;
}

/deep/ .item {
  margin-right: 0 !important;
}

/deep/ .ant-list-item {
  border: 0 !important;
  padding: 0;
}