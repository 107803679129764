.comment {
  background-color: #f5f5f5;
  padding-top: 0.4rem;
  padding-bottom: 1.14rem;
}

.comment .title {
  display: flex;
  align-items: center;
  font-size: 0.2rem;
  color: #f6a75c;
  font-weight: bold;
  margin-bottom: 0.2rem;
}

.comment .title .svg-icon {
  margin-right: 0.12rem;
  font-size: 0.2rem;
}

.comment :deep(textarea).ant-input {
  font-size: 0.18rem;
  line-height: 1.556;
  padding: 0.2rem;
  margin-bottom: 0.2rem;
}

.comment .submit {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.4rem;
}

.comment .submit span {
  font-size: 0.16rem;
  color: #bbbbbb;
}

.comment .submit button {
  text-align: center;
  min-width: 1.2rem;
  line-height: 0.4rem;
  font-size: 0.18rem;
  color: #fff;
  border-radius: 0.03rem;
  background-color: #f49133;
  cursor: pointer;
  transition: .3s;
}

.comment .submit button:hover {
  box-shadow: 0 0 0.1rem 0 #f49133;
}

.comment .list_header {
  padding: 0.2rem 0;
  border-bottom: 0.01rem solid #d5d5d5;
  display: flex;
}

.comment .list_header span {
  line-height: 1;
  font-size: 0.18rem;
  color: #888888;
  transition: .3s;
  cursor: pointer;
  padding: 0 0.12rem;
  border-left: 0.01rem solid #bbbbbb;
}

.comment .list_header span:first-of-type {
  padding-left: 0%;
  border-left: 0;
}

.comment .list_header span.active,
.comment .list_header span:hover {
  color: #000;
}

.comment .ant-list-item {
  padding: 0.3rem 0;
  border-color: #d5d5d5;
  display: flex;
  align-items: stretch;
}

.comment .ant-list-item .avatar {
  flex-shrink: 0;
  text-align: center;
}

.comment .ant-list-item .avatar .icon_user {
  font-size: 0.6rem;
}

.comment .ant-list-item .avatar :deep(.ant-avatar-string) {
  transform: translateX(-50%) !important;
}

.comment .ant-list-item .avatar :deep(.ant-avatar) {
  margin-bottom: 0.1rem;
}

.comment .ant-list-item .avatar .name {
  font-size: 0.18rem;
  color: #000000;
  line-height: 1;
  font-weight: bold;
}

.comment .ant-list-item .txt {
  flex: 1;
  padding-left: 0.5rem;
}

.comment .ant-list-item .txt .usertitle {
  font-size: 0;
  display: flex;
  align-items: flex-start;
}

.comment .ant-list-item .txt .usertitle span {
  font-size: 0.16rem;
  padding: 0 0.2rem;
  line-height: 0.3rem;
  color: #fff;
  font-weight: bold;
  background-color: #f49133;
  border-radius: 0.15rem;
  margin-bottom: 0.1rem;
  margin-right: 0.1rem;
}

.comment .ant-list-item .txt .content {
  font-size: 0.16rem;
  color: #555555;
  line-height: 1.75;
  margin-bottom: 0.2rem;
}

.comment .ant-list-item .txt .handle {
  display: flex;
  margin-bottom: 0.2rem;
}

.comment .ant-list-item .txt .handle .item {
  display: flex;
  align-items: center;
  margin-right: 0.35rem;
  cursor: pointer;
  font-size: 0.18rem;
}

.comment .ant-list-item .txt .handle .item.active .svg-icon {
  color: #f49133;
}

.comment .ant-list-item .txt .handle .item.active span {
  color: #f49133;
}

.comment .ant-list-item .txt .handle .svg-icon {
  color: #bbb;
  margin-right: 0.1rem;
  transition: .3s;
}

.comment .ant-list-item .txt .handle span {
  color: #555555;
}

.comment .ant-list-item .txt .connment_list {
  padding: 0.3rem;
  background-color: #eee;
}

.comment .ant-list-item .txt .connment_list .item {
  font-size: 0.16rem;
  color: #555555;
  line-height: 1.75;
}

.comment .ant-list-item .txt .connment_list .sum {
  color: #f49133;
  cursor: pointer;
}

.comment .ant-list-item:last-child {
  border-bottom: 0.01rem solid #e8e8e8;
}

.comment .show_sum {
  margin-top: 0.5rem;
  text-align: center;
}

.comment .show_sum .ant-btn {
  min-width: 3rem;
  line-height: 0.6rem;
  height: 0.6rem;
  font-size: 0.18rem;
  color: #000000;
  border-radius: 0.3rem;
  border-color: #eeeeee;
  background-color: #eee;
}

.comment .show_sum .ant-btn .anticon {
  color: #bbb;
}

.comment .show_sum .ant-btn:hover {
  background-color: #00ffc0;
  border-color: #00ffc0;
  box-shadow: 0 0 0.1rem 0 #00ffc0;
}

.comment .show_sum .ant-btn:hover .anticon {
  color: #000;
}

.comment .show_sum .ant-btn::before {
  background-color: #eee;
}