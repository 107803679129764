.item {
  background-color: #f5f5f5;
  padding: 0.2rem;
  width: 100%;
  margin-bottom: 0.2rem;
  display: flex;
  position: relative;
  transition: 0.3s;
}

.item:hover {
  box-shadow: 0 0.03rem 0.06rem -0.04rem #0000001f 1f, 0 0.06rem 0.16rem #00000014 14, 0 0.09rem 0.28rem 0.08rem #0000000d 0d;
}

.item:hover h2 pre {
  color: #f49133;
}

.item:not(:nth-of-type(3n)) {
  margin-right: 0.3rem;
}

.item .tag {
  position: absolute;
  left: 0;
  top: 0;
  line-height: 0.3rem;
  padding: 0 0.1rem;
  background-color: #f49133;
  color: #fff;
  border-radius: 0 0 0.13rem 0;
}

.item .pic {
  margin-right: 0.3rem;
  flex-shrink: 0;
  cursor: pointer;
}

.item .pic img {
  width: 1.2rem;
  height: 1.8rem;
  background-color: #eee;
  object-fit: cover;
}

.item .txt {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.item h2 {
  display: flex;
  cursor: pointer;
}

.item h2 pre {
  font-size: 0.17rem;
  color: #333;
  line-height: 1.566;
  flex: 1;
}

.item h2 i {
  flex-shrink: 0;
  color: #999;
  transition: 0.3s;
  margin-left: 0.1rem;
}

.item h2 i .icon_collect {
  font-size: 0.2rem;
}

.item h2 i:hover,
.item h2 i.active {
  color: #f49133;
}

.item .des {
  font-size: 0.16rem;
  color: #555555;
  line-height: 1.5;
  min-height: 4.5em;
  padding-right: 0.2rem;
  cursor: pointer;
}

.item .time {
  font-size: 0.16rem;
  color: #888888;
}