.nav {
  padding-bottom: 0.3rem;
}

.nav-list {
  display: flex;
  flex-wrap: wrap;
}

.nav-list .item {
  width: calc(33.33% - 0.15rem);
  background-color: #fafafb;
  padding: 0.7rem 0.38rem 0.05rem;
  border-radius: 0.1rem;
  text-align: center;
  margin-bottom: 0.1rem;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: 0.3s;
}

.nav-list .item:not(:nth-of-type(3n)) {
  margin-right: 0.1rem;
}

.nav-list .item:hover {
  box-shadow: 0 0 0.1rem 0 #ddd;
}

.nav-list .item .icon {
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  background-color: #dcfdf4;
  position: relative;
  margin: 0 auto 0.3rem;
}

.nav-list .item .icon img {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  max-width: 90%;
}

.nav-list .item h2 {
  color: #000;
  font-weight: bold;
  font-size: 0.24rem;
  line-height: 1;
  margin-bottom: 0.2rem;
}

.nav-list .item .des {
  font-size: 0.16rem;
  color: #f6a75c;
  line-height: 1.5;
}

.nav-list .item .info {
  color: #555;
  font-size: 0.16rem;
  line-height: 1.5;
  min-height: 7.5em;
}

.nav-list .item .handle {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  transition: 0.3s;
  opacity: 0;
}

.nav-list .item .handle:hover {
  opacity: 1;
}

.nav-list .item .handle span {
  line-height: 0.4rem;
  border-radius: 0.2rem;
  background-color: #f57d32;
  color: #fff;
  min-width: 1.2rem;
  padding: 0 0.2rem;
  font-size: 0.16rem;
  margin-bottom: 0.15rem;
  transition: 0.3s;
}

.nav-list .item .handle span:last-of-type {
  margin-bottom: 0;
}

.nav-list .item .handle span:hover {
  opacity: 0.9;
}