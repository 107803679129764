h2 {
  padding: 0 0 0.2rem !important;
  display: flex;
  align-items: center;
  color: #f6a75c;
}

h2 .icon {
  font-size: 0.2rem;
  margin-right: 0.1rem;
}

h2 span {
  font-weight: bold;
  font-size: 0.2rem;
}

.h2_inner {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.h2_inner .h2_header {
  display: flex;
  align-items: center;
}

.h2_inner .page /deep/ .ant-pagination-simple-pager {
  display: none;
}

.h2_inner .page /deep/ .ant-pagination-prev,
.h2_inner .page /deep/ .ant-pagination-next {
  width: 0.3rem;
  height: 0.3rem !important;
  line-height: 0.3rem !important;
  margin-left: 0.1rem;
  margin-right: 0 !important;
}