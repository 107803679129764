/deep/ .ant-tree-switcher {
  display: none !important;
}

/deep/ .ant-tree-child-tree {
  padding: 0 !important;
}

.tree_title {
  font-size: 0.18rem;
  color: #333333;
  line-height: 2;
  white-space: pre-wrap;
  display: block;
  display: flex;
}

.tree_title a {
  color: #333333;
}

.tree_title .level {
  flex-shrink: 0;
}

.tree_title .title {
  flex: 1;
}

/deep/ .ant-tree > li {
  padding: 0 !important;
}

/deep/ .ant-tree > li span {
  height: auto;
  padding: 0;
  border: 0 !important;
}

/deep/ .ant-tree > li > ul {
  padding-bottom: 0.35rem !important;
}

.catelog .catelog_main {
  max-height: 10rem;
  overflow-y: auto;
  padding-right: 1rem;
}

.catelog .catelog_main::-webkit-scrollbar {
  width: 0.06rem;
  border-radius: 0.03rem;
  background-color: #e5e5e5;
}

.catelog .catelog_main::-webkit-scrollbar-thumb {
  width: 100%;
  border-radius: 0.03rem;
  background-color: #f6a75c;
}