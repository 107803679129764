h1 {
  padding-top: 0.1rem;
  font-size: 0.24rem;
  color: #333;
  line-height: 1.25;
  margin-bottom: 0.07rem;
}

.des {
  font-size: 0.18rem;
  color: #333;
  line-height: 1.778;
  padding-bottom: 0.3rem;
}

.tag_box {
  font-size: 0;
}

.section {
  display: flex;
  padding-bottom: 1.1rem;
  padding-top: 0.4rem;
}

.section .left {
  width: 0;
  flex: 1;
}

.section .right {
  width: 4.9rem;
  padding-left: 0.3rem;
  flex-shrink: 0;
}

:deep(.model_main) {
  height: auto;
}

:deep(.jsmind) {
  width: 100vw;
  height: 100vh;
  top: 0;
}