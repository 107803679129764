.list {
  margin-bottom: 0.2rem;
}

.list .item .dot {
  font-size: 0.18rem;
  color: #555555;
  line-height: 2.222;
  padding-left: 0.25rem;
  position: relative;
}

.list .item .dot::before {
  content: '';
  width: 0.05rem;
  height: 0.05rem;
  border-radius: 50%;
  background-color: #555;
  position: absolute;
  left: 0.06rem;
  top: 0;
  bottom: 0;
  margin: auto;
}

.list .item .dot:hover {
  color: #f6a75c;
}

.list .item .dot:hover::before {
  background-color: #f6a75c;
}

.change .inner {
  display: flex;
  align-items: center;
  color: #888888;
  cursor: pointer;
}

.change .inner .svg-icon {
  font-size: 0.16rem;
  margin-right: 0.1rem;
}

.change .inner .svg-icon.active {
  animation: circle 1s linear;
}

.change .inner:hover {
  color: #f6a75c;
}

@keyframes circle {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

/deep/ .ant-list-item {
  padding: 0;
  border: 0 !important;
}